import React from "react";

export const GlobalStateContext = React.createContext();
export const GlobalDispatchContext = React.createContext();

const initialState = {
  landingAnimationCount: 0,
  presentationModalOpen: false
}

function reducer(state, action) {
  switch (action.type) {
    case "landing_animation_count": {
      return {
        ...state,
        landingAnimationCount: state.landingAnimationCount+1,
      }
    }
    case "presentation_modal_open": {
      return {
        ...state,
        presentationModalOpen: state.presentationModalOpen ? false : true,
      }
    }
    default:
      throw new Error("Bad Action Type")
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider;
